import React from 'react'
import { NavLink } from 'react-router-dom';

export default function Menu() {

  function toggleNav(){
    const menu = document.getElementById('menu');
    if (menu.classList.contains("menuOpen")) {
      menu.classList.remove("menuOpen")
    }
    else{
      menu.classList.add("menuOpen")
    }
  }

  return (
    <div id='menu'>
        <svg onClick={toggleNav} id="burgerIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <g id="burgerLines">
            <rect className='burgerLine' width="500" height="50" />
            <rect className='burgerLine' y="177.27" width="500" height="50" />
            <rect className='burgerLine' y="354.55" width="500" height="50" />
          </g>
        </svg>

        <nav id='menuNav'>
            <NavLink className='navLink' to="/" end>Home</NavLink>
            <NavLink className='navLink' to="/projects">Projects</NavLink>
            <NavLink className='navLink' to="/about">About</NavLink>
            <NavLink className='navLink' to="/contact">Contact</NavLink>
        </nav>
    </div>
  )
}
