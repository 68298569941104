import React from "react";
import portal from "../images/portal.svg";

export default function Project({ project }) {
	const image = project._embedded["wp:featuredmedia"][0].source_url;

	return (
			<div className='container movePoint'>
				<div className='container-left'>
					<h2 className='container-text-title'>{project.title.rendered}</h2>
					<p className='container-text-body'>
            {project.acf.body}
					</p>
				</div>
				<div className='container-right'>
						<div className='container-image-back'>
							<img
								src={portal}
								style={{ animationDelay: "0.5s", animationDuration: "15s", maxWidth: "320px", maxHeight: "320px" }}
								alt=''
							/>
							<img
								src={portal}
								style={{ animationDelay: "0s", animationDuration: "8s", maxWidth: "320px", maxHeight: "320px" }}
								alt=''
							/>
						</div>
						<a href={project.acf.website} target='_blank'
							className='container-image container-image-hoverable'
							style={{
								backgroundImage: `url(${image})`,
								backgroundPosition: "top",
							}}></a>
				</div>
			</div>
	);
}
