import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

export default function HelpDialog({location, byUser}) {

    let controllablePaths = ["/projects", "/about"]
	let isControllable = controllablePaths.includes(location);

    if(!isControllable && !byUser) return null;


    const dialog = useRef()

    let diaelem;

    useEffect(() => {
        if (!dialog.current.open) {
            dialog.current.showModal()
        }
        diaelem = dialog.current

    }, [])

    function closeModal(){
        diaelem.remove()
    }
    
    function changePref() {
        sessionStorage.setItem("showDialog", !document.querySelector("#doNotShow").checked);
    }


    return (
        <dialog ref={dialog}>
            <h3>Movement</h3>
            <p>Use the <code>UP & DOWN ARROWS</code> on your keyboard to navigate the page. 
            <br /> 
            <br /> 
            Alternatively you can use the <code>CONTROLS</code> tab at the bottom of the page.</p>
            <div className="check-container">
                <label htmlFor="doNotShow">Do not show me this again:</label>
                <input onChange={changePref} type="checkbox" name="doNotShow" id="doNotShow" />
            </div>
            <button onClick={closeModal}>Alright, cool!</button>
        </dialog>
    )
}
