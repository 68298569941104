import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { useLocation, NavLink } from "react-router-dom";
import arrow from "../images/CtrlArrow.svg";
import ParticleMenu from "./ParticleMenu";

export default function ParticleGen({ location }) {
	if (location.pathname === "/contact") {
		return null;
	}
	let [helper, setHelper] = useState(true);
	let [particleState, setParticleState] = useState(false);
	let [renderCount, setRenderCount] = useState(0);

	let controllablePaths = ["/projects", "/about"]
	let isControllable = controllablePaths.includes(location.pathname);

    let stops;

	useEffect(() => {
		if (location.pathname !== "/") {
			setHelper(false);
		}
        
		setRenderCount((renderCount += 1));
	}, []);

	function hideParticleGen(e) {
		if (e.currentTarget.classList.contains("blob")) {
			if (helper) {
				setHelper(false);
			}
			const menu = document.querySelector(".menuTest");
			if (menu.classList.contains("testOpen")) {
				menu.classList.remove("testOpen");
			} else {
				menu.classList.add("testOpen");
			}
		}
		if (e.currentTarget.classList.contains("testOpen")) {
			e.currentTarget.classList.remove("testOpen");
		}
	}

    if (location.pathname === "/projects") {
        stops = {
            direction: "vertical",
            startPos: ".content-container",
            start: ".wrapTitle",
            end: ".more",
            next: [],
            index: 0,
        };
    }

    if (location.pathname === "/about") {
        stops = {
            direction: "vertical",
            startPos: ".content-container",
            start: ".wrapTitle",
			end: "",
            next: [],
            index: 0,
        };
    }
	
	let observer = new IntersectionObserver((entries) => fadeInElem(entries), {
		threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
		rootMargin: "0px",
	});

	window.addEventListener("keydown", (e) => {
		if (!isControllable) return;
		e.preventDefault();
		if (!isControllable || document.querySelector("dialog")) return;
		onParticleKey(e.key)

	});

	addEventListener("resize", function onParticleResize() {
		if (!isControllable) return;
		if (stops.next.length === 0) {
			return;
		}
		stops.next = [];
		createMovePoints();
		moveToPos(stops.next[stops.index]);
	});

	function createMovePoints() {
		const elems = document.querySelectorAll(".movePoint");
		const ball = document.getElementById("blob-container");
		const startPos = document.querySelector(stops.startPos);
		const start = document.querySelector(stops.start);
		
		elems.forEach((e) => {
			stops.next.push({
				x: Math.floor(window.innerWidth / 2),
				y: Math.floor(
					e.offsetTop +
						e.offsetHeight / 2 -
						ball.offsetHeight/2 +
						startPos.offsetTop
				),
				elem: e,
			});
		});
		stops.next.sort((a, b) => {
			return a.y - b.y;
		});

		if (stops.end) {
			const end = document.querySelector(stops.end);
			stops.next = [
				{
					x: Math.floor(window.innerWidth / 2),
					y: Math.floor(start.offsetHeight / 2),
					elem: start,
				},
				...stops.next,
				{
					x: Math.floor(window.innerWidth / 2),
					y: Math.floor(end.offsetTop + end.offsetHeight / 2),
					elem: end,
				},
			];
			
		}
		else{
			stops.next = [
				{
					x: Math.floor(window.innerWidth / 2),
					y: Math.floor(start.offsetHeight / 2),
					elem: start,
				},
				...stops.next
			];
		}

	}

	function fadeInElem(entries) {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				if (entry.intersectionRatio >= 0.7) {
					entry.target.classList.add("show-container")	
				}
				else{
					entry.target.classList.remove("show-container")
				}
			}
		});
	}

	function moveBall(e) {
		if (stops.next.length > 0) {
			if (e == "ArrowUp") {
				if (stops.index >= 1) {
					stops.index -= 1;
					moveToPos(stops.next[stops.index]);
				}
			}
			if (e == "ArrowDown") {
				if (stops.next.length <= stops.index + 1) return;
				stops.index += 1;
				moveToPos(stops.next[stops.index]);
			}
			if (e == "ArrowLeft") {
				if (stops.index >= 1) {
					stops.index -= 1;
					moveToPos(stops.next[stops.index]);
				}
			}
			if (e == "ArrowRight") {
				if (stops.next.length <= stops.index + 1) return;
				stops.index += 1;
				moveToPos(stops.next[stops.index]);
			}
		}
	}

	function onParticleKey(e){
		if (stops.next.length < 1) {
			createMovePoints();
			document.querySelectorAll(".movePoint").forEach((point) => {
				observer.observe(point);
			});
		}

		moveBall(e);
	}

	function moveToPos(pos) {
		const ball = document.getElementById("blob-container");

		ball.style.left = stops.direction === "vertical" ? "" : pos.x + "px";
		ball.style.top = stops.direction === "horizontal" ? "" : pos.y + "px";
		setTimeout(() => {
			pos.elem.scrollIntoView({behavior: "smooth", block: "center"})
		},300);
	}

	function moveButtons(dir) {
		switch (dir) {
			case "up":
				onParticleKey("ArrowUp")
				break;
		
			case "down":
				onParticleKey("ArrowDown")
				break;
		
			case "top":
				toTop()
				break;
		
			default:
				break;
		}
	}


    function toTop() {
        stops.index = 0;
        moveToPos(stops.next[stops.index]);
    }

	class Particle {
		constructor(size, parent, x, y, lifetime) {
			this.height = size;
			this.width = size;
			this.parent = parent;
			this.x = x;
			this.y = y;
			this.lifetime = lifetime;
		}

		spawnParticle() {
			let particle = document.createElement("div");
			let parent = document.querySelector(this.parent);
			particle.classList.add("particle");
			particle.classList.add("fade-in");
			particle.style.width = this.width + "px";
			particle.style.height = this.height + "px";
			particle.style.left = this.x + "px";
			particle.style.top = this.y + "px";
			parent.appendChild(particle);
			setTimeout(() => {
				parent.removeChild(particle);
			}, this.lifetime);
		}
	}

	function generateParticles() {
		const ball = document.getElementById("blob-container");

		const parent = "#root";

		const size = Math.floor(Math.random() * (30 - 10 + 1)) + 10;
		const x =
			ball.offsetLeft -
			ball.offsetWidth / 2 +
			(Math.floor(Math.random() * (70 - 10 + 1)) + 10);
		const y = ball.offsetTop + Math.floor(Math.random() * (70 - 30 + 1)) + 20;

		const p = new Particle(size, parent, x, y, 3000);
		p.spawnParticle();
	}

	if (renderCount === 1) {
		if (!particleState && !document.querySelector(".particle")) {
			const particleInterval = setInterval(() => {
				if (!document.getElementById("blob-container"))
					clearInterval(particleInterval);
				generateParticles();
			}, 200);
			setParticleState(true);
		}
	}

	function showControls() {
		const controls = document.querySelector(".controls")
		if (controls.classList.contains("controlsOpen")) {
			controls.classList.remove("controlsOpen")
		}
		else{
			controls.classList.add("controlsOpen")
		}
	}

	return (
		<div>
			<>
				<div id='blob-container'>
					<div className='blob' onClick={hideParticleGen}>
						<div className='rotaters'>
							<div className='rotater1'></div>
							<div className='rotater2'></div>
							<div className='rotater3'></div>
						</div>
						<div className='ball'></div>
					</div>
					<ParticleMenu onClick={hideParticleGen} location={location.pathname} />
					{helper && (
						<div className='clickMe'>
							<h2>^</h2>
							<h2>Click me to navigate</h2>
						</div>
					)}
				</div>
				{
					isControllable  &&
					<div className='controls'>
						<div onClick={showControls} className="handle">Controls</div>
						<div className="controls-body">
							<div className="arrows">
								<img onClick={() => moveButtons("up")} className="clickable" style={{transform: "rotate(180deg)"}} src={arrow} alt="arrow up" />
								<img onClick={() => moveButtons("down")} className="clickable" src={arrow} alt="arrow down" />
							</div>
							<div className="controls-line"></div>
							<button onClick={() => moveButtons("top")} className="clickable">To Top</button>
						</div>
					</div>
				}
			</>
		</div>
	);
}
