import Menu from "../components/Menu";
import ParticleGen from "../components/ParticleGen";
import Logo from '../images/DeluLogoWhite.svg'

export default function Home() {
  return (
    <div className="wrap" id="homeWrap">
      <img id="logo" src={Logo} alt="Delu Logo" />
      <h3>Welcome to my portfolio!</h3>
      <p className="text">This is a semi-interactive experience, so the navigation will be different than what you might be used to. But regardless of that I hope you enjoy your stay.</p>
      <p className="text">Click the funny-looking "blob" in the center to begin!</p>
      
    </div>
  );
}
