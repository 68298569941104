import React from 'react';
import fb from "../images/facebook.svg";
import insta from "../images/instagram.svg";
import linked from "../images/linkedin.svg";
import git from "../images/github.svg";

export default function Socials() {
  return (
    <div className='socials'>
        <a target="_blank" href="https://www.instagram.com/andreasdelu/"><img src={fb} alt='facebook' /></a>
        <a target="_blank" href="https://www.facebook.com/andreas.deleuranholmnielsen/"><img src={insta} alt='instagram' /></a>
        <a target="_blank" href="https://www.linkedin.com/in/andreas-deleuran-nielsen-8b268b21b/"><img src={linked} alt='linkedin' /></a>
        <a target="_blank" href="https://github.com/andreasdelu"><img src={git} alt='github' /></a>
    </div>
  )
}
