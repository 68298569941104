import React from "react";
import { useState, useEffect } from "react";
import Menu from "../components/Menu";
import Project from "../components/Project";

export default function Projects() {

  
  const [projects, setProjects] = useState([]);
  
	useEffect(() => {
    async function getProjects() {
      const res = await fetch(
        "https://headless.delu.dk/wp-json/wp/v2/posts?_embed"
        );
        const data = await res.json();
        setProjects(data);
      }

		getProjects();
	}, []);

	return (
		<div className='wrap' id='projectWrap'>
			<div className='wrapTitle'>
				<h1 className='title'>Projects</h1>
				<p className="text">A handpicked collection of my favorite projects that I've worked on during the years</p>
			</div>
			<div className='content-container'>
				{projects.map((prj) => <Project key={prj.id} project={prj} />
				)}
			</div>
			<h2 className='more'>And more to come...</h2>
		</div>
	);
}
