import React from 'react'

export default function ParticleMenu({ location }) {
    const links = [
        {name: "Home", url: "/"},
        {name: "Projects", url: "/projects"},
        {name: "About", url: "/about"},
        {name: "Contact", url: "/contact"},
    ]
  return (
    <div className='menuTest'>
        {/* <NavLink className='menupoint' to='/' end>
            Home
        </NavLink>
        <NavLink className='menupoint' to='/projects'>
            Projects
        </NavLink>
        <NavLink className='menupoint' to='/about'>
            About
        </NavLink>
        <NavLink className='menupoint' to='/contact'>
            Contact
        </NavLink> */}
        {
            links.map((link, i) => 
                <a key={i} className={(link.url === location) ? "menupoint active" : "menupoint"} href={link.url}>
                    {link.name}
                </a>
            )
        }
    </div>
  )
}
