import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ParticleGen from "./components/ParticleGen";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import HelpDialog from "./components/HelpDialog";


function App() {

  let showDialog = JSON.parse(sessionStorage.getItem("showDialog"))

  let location = useLocation();

  useEffect(() => {
    
  }, [location])

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="projects" element={<Projects />}/>
        <Route path="about" element={<About />}/>
        <Route path="contact" element={<Contact />}/>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ParticleGen location={location} />
      {showDialog && 
        <HelpDialog byUser={false} location={location.pathname} />
      }
    </>
  );
}

export default App;
