import React from "react";
import Menu from "../components/Menu";
import portal from "../images/portal.svg";
import profilePic from "../images/andreas.jpg";
import icons from '../images/icons.svg'
import editing from '../images/editing.svg'
import letter from '../images/letter.svg'

export default function About() {
	return (
		<div className='wrap' id='aboutWrap'>
			<div className='wrapTitle'>
				<h1 className='title'>About Me</h1>
				<p className='text'>
					If you want to learn more about me you've come to the right place.
					Everything you need to know is right on this page!
				</p>
			</div>
			<div className='content-container'>
				<div className='container movePoint'>
					<div className='container-left'>
						<h2 className='container-text-title'>Hi, I'm Andreas</h2>
						<p className='container-text-body'>
							I'm a multimediadesigner based in Aarhus, Denmark. I chose this
							careerpath because I love design and programming and it's
							something i enjoy spending my freetime on. Creativity is very
							important to me, and to be able to express that creativity in
							multiple mediums is something i appreciate being able to do,
							whether it's through music, proramming, graphic design etc.
						</p>
					</div>
					<div className='container-right'>
						<div className='container-image-back'>
							<img src={portal} style={{animationDelay: "0.5s", animationDuration: "15s"}} alt="" />
							<img src={portal} style={{animationDelay: "0s", animationDuration: "8s"}} alt="" />
						</div>
						<div
							className='container-image'
							style={{
								backgroundImage: `url(${profilePic})`,
								backgroundPosition: "top",
							}}></div>
					</div>
				</div>
				<div className='container movePoint'>
					<div className='container-left'>
						<h2 className='container-text-title'>Programming: What can I do?</h2>
						<p className='container-text-body'>
							As mentioned before, I really love programming and having to solve problems using code!
							I like the challenge of picking up a new framework, library or language. So I have gathered
							experience in using a lot of things from regular JavaScript, jQuery and React in the frontend, 
							to Node.js and express in the backend. Whatever i find interesting i want to learn!
						</p>
					</div>
					<div className='container-right'>
						<div className='container-image-back'>
							<img src={portal} style={{animationDelay: "0.5s", animationDuration: "10s"}} alt="" />
							<img src={portal} style={{animationDelay: "0s", animationDuration: "4s"}} alt="" />
						</div>
						<div
							className='container-image'
							style={{
								backgroundImage: `url(${icons})`,
								backgroundPosition: "center",
								backgroundSize: "80%",
								backgroundRepeat: "no-repeat"
							}}></div>
					</div>
				</div>
				<div className='container movePoint'>
					<div className='container-left'>
						<h2 className='container-text-title'>What about design?</h2>
						<p className='container-text-body'>
							I can do that too! Whether it's photo or video-editing, logo creation or making graphics. 
							Graphic design in any shape or form has always been a way for me to express myself. I've used photoshop since I was 12
							and After Effects and Premiere Pro to edit videos since i was 14! So whatever graphical or video content i need to produce
							I'll do that no problem!
						</p>
					</div>
					<div className='container-right'>
						<div className='container-image-back'>
							<img src={portal} style={{animationDelay: "0.5s", animationDuration: "10s"}} alt="" />
							<img src={portal} style={{animationDelay: "0s", animationDuration: "4s"}} alt="" />
						</div>
						<div
							className='container-image'
							style={{
								backgroundImage: `url(${editing})`,
								backgroundPosition: "center",
								backgroundSize: "70%",
								backgroundRepeat: "no-repeat"
							}}></div>
					</div>
				</div>
				<div className='container movePoint'>
					<div className='container-left'>
						<h2 className='container-text-title'>Contact me!</h2>
						<p className='container-text-body'>
							If any of this makes you want to work with me or just have a chat. 
							Then you should send me a message and I'll get back to you as fast as possible!
							Use the link below.
						</p>
						<a href="/contact">Contact me</a>
					</div>
					<div className='container-right'>
						<div
							className='container-image'
							style={{
								backgroundImage: `url(${letter})`,
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "70%",
								filter: "invert(1)"
							}}></div>
					</div>
				</div>
			</div>
			<div className='end'></div>
		</div>
	);
}
