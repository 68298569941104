import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Socials from './components/Socials';

if (sessionStorage.getItem("showDialog") == null) {
  sessionStorage.setItem("showDialog", true);
}



ReactDOM.render( 
  <React.StrictMode>
    <div className='page-bg'></div>
    <BrowserRouter>
      <App />
      <Socials />
    </BrowserRouter>
    <div className='grain'></div>
  </React.StrictMode>,
  document.getElementById('root')
);


