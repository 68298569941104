import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import Menu from "../components/Menu";
import { useState } from "react";


export default function Contact() {

  const [messageSent, setMessageSent] = useState(false)

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setMessageSent(true)

    emailjs.sendForm('service_nuboowi', 'template_dm2e93w', form.current, '5n_pvVIsqGkOOAeHa')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      setTimeout(() => {
        location.href = "/"
      }, 2000);
  };

	return (
		<div className='wrap' id='contactWrap'>
			<Menu />
			<div className='wrapTitle'>
				<h1 className='title'>Contact Me</h1>
			</div>
			<div className='contact-body'>
				<div className='contact-form'>
          {!messageSent &&
            <form ref={form} onSubmit={sendEmail}>
              <label htmlFor="formName">What's your name?</label>
              <input name="user_name" id="formName" type="text" placeholder="Name" required />
              <label htmlFor="formMail">What's your email?</label>
              <input name="user_email" id="formMail" type="email" placeholder="Email" required />
              <label htmlFor="formBody">What's your message?</label>
              <textarea name="message" id="formBody" cols="30" rows="10" placeholder="Message" required ></textarea>
              <button type="submit">Send</button>
            </form>
          }
          {messageSent &&
            <div className="form-cover">
              <h2>Message sent <br /> Thank you!</h2>
            </div>
          }
				</div>
			</div>
		</div>
	);
}
